<template>
  <div id="getRoc">
    <goBack></goBack>
    <broadPage>
      <div slot="content">
        <div class="content">
          <div class="title">
            <p class="get-title">Get DO</p>
            <p class="get-title">
              <router-link to="/adminview/orders">Redeem ROC ></router-link>
            </p>
          </div>
          <!-- from -->
          <div class="get-content">
            <p>From</p>
            <div class="border-contnet">
              <el-row>
                <el-col :span="10">
                  <div class="border-rignt">
                    <div class="item-left">
                      <img src="./images/balances.svg" />
                      <span>ROC</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="14">
                  <div class="item-right">
                    <div>
                      <span>Balance: </span>
                      <span>{{rocMount}}</span>
                    </div>
                    <el-input
                      v-model="coinFrom"
                      placeholder="0.00"
                      class="noBorder"
                      type="number"
                      @input="changeFrom"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- to -->
          <div class="get-content">
            <p>To</p>
            <div class="border-contnet">
              <el-row>
                <el-col :span="10">
                  <div class="border-rignt">
                    <div class="item-left">
                      <img src="./images/do.svg" />
                      <span>DO</span>
                    </div>
                  </div>
                </el-col>
                <el-col :span="14">
                  <div class="item-right">
                    <div>
                      <span>Balance: </span>
                      <span>{{doMount}}</span>
                    </div>
                    <el-input
                      v-model="coinTo"
                      placeholder="0.00"
                      class="noBorder"
                      type="number"
                      @input="changeTo"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- <button @click="approveRoc">授权roc</button>
          <button>授权roc</button> -->
          <div class="btn btn-middle btn-primary mt-2 mb-3 router-link-exact-active router-link-active"  @click="!isDesiabled && !tradeLoading && transform()" :class="{'btn-desiabled': isDesiabled}">
            <i class="el-icon-loading" v-show="tradeLoading"></i> {{Trade}}
          </div>
          <div class="price">
            <el-row type="flex" justify="space-between">
              <el-col>
                <p>Price</p>
              </el-col>
              <el-col class="text-right">
                <span>1</span>
                <span> ROC</span>
                <span> ≈ </span>
                <span>{{rocPrice/2}}</span>
                <span> DO</span>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between" class="mt-2 mb-2">
              <el-col>
                <p>Slippage Tolerance
                  <el-tooltip class="item" effect="dark" content="The difference between the market price and estimated price due to trade size." placement="top">
                    <i class="fa fa-question-circle"></i>
                  </el-tooltip>
                </p>
              </el-col>
              <el-col class="text-right">
                  <el-input-number
                  size="mini"
                  @change="handleChange"
                  :min="1"
                  :max="100"
                  v-model="num"
                ></el-input-number>
                <div class="number-unit">%</div>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between" class="mt-2 mb-2">
              <el-col>
                <p>Transaction Deadline
                  <el-tooltip class="item" effect="dark" content="Your transaction will revert if is pending for more than this long." placement="top">
                    <i class="fa fa-question-circle"></i>
                  </el-tooltip>
                </p>
              </el-col>
              <el-col class="text-right">
                <el-input-number
                  size="mini"
                  @change="handleChange2"
                  :min="1"
                  v-model="num2"
                ></el-input-number>
                <div class="number-unit">min</div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </broadPage>
  </div>
</template>

<script>
import broadPage from "../../components/broadPage.vue";
import goBack from '../../components/goBack.vue';
import BigNumber from 'bignumber.js'
import { 
  doBlance, 
  rocBlance, 
  RocToDO, 
  BigNum, 
  DoToRoc, 
  debounce, 
  isApproveRoc, 
  approveRoc, 
  getFullDisplayBalance, 
  isApproveDo, 
  ROCContract, 
  DOContract, 
  PRddress,
  PRContract,
  RocPrice,
  getDisplayBalance,
  getDOOrders,
  toolNumber
} from "../../unitls"
import _ from "lodash";
var that = this
export default {
  name: "getRoc",
  data() {
    return {
      num: 1,
      num2: 5,
      coinFrom: "",
      coinTo: "",
      path: "/balances",
      address: localStorage.getItem('account'),
      doMount: 0,
      rocMount: 0,
      Trade: 'Trade',
      isApproveRoc: false,
      isApproveDo: false,
      isDesiabled: false,
      isInOrOut: 'in',
      tradeLoading: false,
      rocPrice: '0.5', // 设置roc的价格 来计算roc兑换do的比例
      indexValue: 0,
    };
  },
  components: { broadPage, goBack },
  mounted () {
    // console.log(`new BigNumber('4.321e+4')`,  new BigNumber('4.321e+4'))
    if (!this.address) {
      this.Trade = 'Connect to Matemask'
      return
    }
    // 获取roc的平均发行价格；
    RocPrice().then(data => {
      this.rocPrice = getDisplayBalance(BigNum(data))
    })
    this.init()
    
  },
  methods: {
    // 动态校验input
    // changeCoinFrom(val) {
    //   console.log(val)
    //   pattern="^[0-9]*[.,]?[0-9]*$"
    //   this.coinFrom = 1
    // },
    handleChange(value) {
      if(!value) {
        this.num = 2;
        this.$nextTick(()=>{
          this.num = 1;
        });
      }
    },
    handleChange2(value) {
      if(!value) {
        this.num2 = 2;
        this.$nextTick(()=>{
          this.num2 = 5;
        });
      } else {
        this.num2 = Math.round(value)
      }
    },
    
    // 获取对应的rousd和roc
    init() {
      if (this.address) {
        this.coinFrom = ''
        this.coinTo = ''
        // 获取do的资产
        doBlance(this.address).then(val => {
          console.log(val, 'domount')
          this.doMount = val
        })
        // 获取roc的资产
        rocBlance(this.address).then(val => {
          this.rocMount = val
        })

        // 判断是否授权roc
        isApproveRoc(this.address).then(data => {
          if (data > 0) {
            this.isApproveRoc = true
            this.Trade = 'Trade'
          }
        })
        // 判断是否授权do
        isApproveDo(this.address).then(data => {
          if (data > 0) {
            this.isApproveDo = true
            this.Trade = 'Trade'
          }
        })
      }
    },

    // 进行交易
    transform() {
      if (!this.coinTo || !this.coinFrom) {
        return;
      }
      const maxApproval = new BigNumber(2).pow(256).minus(1).toFixed();
      this.tradeLoading = true
      if (this.isInOrOut === 'in') {
        // 未授权roc去授权；
        if (this.Trade == 'Approve ROC') {
          // 授权roc
          ROCContract().then(data => {
            data.methods.approve(PRddress, maxApproval).send({ from: this.address}).on('receipt', (receipt) => {
              this.tradeLoading = false
              if (receipt.status) {
                this.init()
              }
            }).catch((err) => {
              this.tradeLoading = false
            })
          })
        } else {
          // 通过roc兑换do
          PRContract().then(data => {
           let minAmount = BigNum(this.coinTo).multipliedBy(BigNum(100 - this.num)).dividedBy(BigNum(100))
           console.log(minAmount.toFixed(), '可以接受兑换的do数量')
            let deadline = (new Date()).valueOf() + 60000 * this.num2
            data.methods.mintDOWithExactAmountOfROC(getFullDisplayBalance(BigNum(this.coinFrom)), getFullDisplayBalance(BigNum(minAmount)), deadline).send({from: this.address}).on('receipt', (receipt) => {
              this.tradeLoading = false
              if (receipt.status) {
                // 成功之后重新获取roc和do
                this.init()
              }
            }).catch((err) => {
              this.tradeLoading = false
            })
          })
        }
      } else {
        if (this.Trade == 'Approve ROC') {
          // 授权roc
          ROCContract().then(data => {
            data.methods.approve(PRddress, maxApproval).send({ from: this.address}).on('receipt', (receipt) => {
              this.tradeLoading = false
              if (receipt.status) {
                this.init()
              }
            }).catch((err) => {
              this.tradeLoading = false
            })
          })
        } else {
          
          // 通过do兑换roc
          PRContract().then(data => {
            let minAmount = BigNum(this.coinFrom).multipliedBy(BigNum(100 + this.num)).dividedBy(BigNum(100))
            console.log(minAmount.toFixed(), 'xxxxxxxxxx')
            let deadline = (new Date()).valueOf() + 60000 * this.num2
            data.methods.mintExactAmountOfDO(getFullDisplayBalance(BigNum(this.coinTo)), getFullDisplayBalance(BigNum(minAmount)), deadline).send({from: this.address}).on('receipt', (receipt) => {
              this.tradeLoading = false
              if (receipt.status) {
                // 成功之后重新获取roc和do
                this.init()
              }
            }).catch((err) => {
              this.tradeLoading = false
            })
          })
        }
      }
    },

    // 根据roc计算do
    // getDO (val) {
    //   if (val > this.rocMount) {
    //     this.Trade = 'mount low'
    //   }
    //   RocToDO(getFullDisplayBalance(BigNum(val)), this.address).then(rocObj => {
    //     console.log(rocObj, 'do')
    //     this.coinTo = rocObj[0]
    //   })
    // },

    // 通过do兑换roc
    // getROC (val) {
    //   console.log('roc')
    //   DoToRoc(getFullDisplayBalance(BigNum(val))).then(rocObj => {
    //     console.log(rocObj, 'roc')
    //     this.coinFrom = rocObj[0]
    //   })
    // },

    debounce (fn, wait) {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        fn()
      }, wait)
    },
    fmt(data) {
      // 保留6位
      if(parseFloat(data)==0) return data;
      let num = BigNum(data ).multipliedBy(1000000).toFixed(0,1)
      return BigNum(num).dividedBy(1000000).toFixed()
    },
    trm(data) {
      data = data.trim();
      const reg = /\d*\.{0,1}\d{0,6}/;
      return reg.exec(data)[0] || '';
    },
    changeFrom() {
      this.Trade = 'Trade'
      this.isDesiabled = false
      this.isInOrOut = 'in'
      this.coinFrom = this.trm(this.coinFrom);
      this.coinTo = BigNum(this.coinFrom).multipliedBy(this.rocPrice).dividedBy(2).toFixed()
      // 去掉0
      this.coinTo = this.fmt(this.coinTo);
      if (this.coinFrom > 0) {
        // 第一步 判断是否授权；
        if (!this.isApproveRoc) {
          this.Trade = 'Approve ROC'
          return
        }
        if (this.coinFrom - this.rocMount > 0) {
          this.Trade = 'ROC Insufficient quantity'
          this.isDesiabled = true
          return
        }
      }
    },

    changeTo() { 
      this.Trade = 'Trade'
      this.isInOrOut = 'out'
      this.coinTo = this.trm(this.coinTo);
      this.coinFrom = BigNum(this.coinTo).multipliedBy(2).dividedBy(BigNum(this.rocPrice)).toFixed()
      this.coinFrom = this.fmt(this.coinFrom);
      this.isDesiabled = false
      if (this.coinTo > 0) {
        // 第一步 判断是否授权；
        if (!this.isApproveRoc) {
          this.Trade = 'Approve ROC'
          return
        }
        if (this.coinFrom - this.rocMount > 0) {
          this.Trade = 'ROC Insufficient quantity'
          this.isDesiabled = true
          return
        }
      }
    }
  },
  watch: {
    // coinFrom: function (newVal, oldVal) {
    //   this.Trade = 'Trade'
    //   this.isDesiabled = false
    //   this.isInOrOut = 'in'
    //   this.coinTo = newVal * 0.5
    //   if (newVal > 0) {
    //     // 第一步 判断是否授权；
    //     if (!this.isApproveRoc) {
    //       this.Trade = 'Approve ROC'
    //       this.isDesiabled = true
    //       return
    //     }
    //     if (newVal > this.rocMount) {
    //       this.Trade = 'roc Insufficient quantity'
    //       this.isDesiabled = true
    //       return
    //     }



    //     const reg = /([0-9]+\.[0-9]{2})[0-9]*/
    //     this.coinFrom = Number(String(newVal).replace(reg, "$1")) === 0.00 ? 0.01 : Number(String(newVal).replace(reg, "$1"))
    //   }
    // },
    coinTo: function (newVal, oldVal) {
      // this.Trade = 'Trade'
      // this.isInOrOut = 'out'
      // this.coinFrom = newVal * 2
      // this.isDesiabled = false
      // if (newVal > 0) {
      //   // 第一步 判断是否授权；
      //   if (!this.isApproveDo) {
      //     this.Trade = 'Approve DO'
      //     this.isDesiabled = true
      //     return
      //   }
      //   if (newVal > this.doMount) {
      //     this.Trade = 'do Insufficient quantity'
      //     return
      //   }
      // }
    }
  }
};
</script>

<style lang="less" scoped>
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
  /deep/ input[type="number"]{
      -moz-appearance: textfield;
  }
</style>
<style lang="less" scoped>
#getRoc {
  .title {
    display: flex;
    justify-content: space-between;
    .get-title a{
      cursor:pointer;
    }
  }
  .noBorder /deep/ .el-input__inner {
    border: 0 !important;
    height: 32px;
    line-height: 32px;
    padding: 0 15px 0 0px;
    padding-right: 0;
    font-size: 20px;
    color: #828282 !important;
    background: #f6f8fb;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:focus {
      box-shadow: none !important;
    }
  }
  .content {
    padding: 30px;
    .get-title {
      font-family: ParmaPetit;
      font-size: 24px;
      color: #085da3;
      margin-bottom: 30px;
    }
    .get-content {
      p {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #4f4f4f;
        margin-bottom: 15px;
      }
      .border-contnet {
        border: 1px solid #e0e0e0;
        margin-bottom: 18px;
        .border-rignt {
          border-right: 1px solid #e0e0e0;
        }
        .item-left {
          vertical-align: middle;
          display: inline-block;
          padding: 25px 0px 25px 20px;
          img {
            margin-top: -6px;
          }
          span {
            font-family: Louis George Cafe;
            font-size: 20px;
            color: #085da3;
            margin-left: 10px;
          }
        }
        .item-right {
          background-color: #f6f8fb;
          padding: 12px 0px 12px 8px;
          height: 80px;
          span {
            font-family: Louis George Cafe;
            font-size: 14px;
            line-height: 140%;
            color: #828282;
          }
          p {
            font-family: Louis George Cafe;
            font-size: 20px;
            color: #828282;
          }
        }
      }
    }
    .btn {
      width: 100%;
    }
    .price {
      position: relative;
      p {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #828282;
      }
      span {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #4f4f4f;
      }
      .number-unit {
        font-size: 14px;
        color: #4f4f4f;
        position: absolute;
        right: 30px;
        top: 3px;
      }
    }
    /deep/.el-input-number--mini .el-input__inner{
    padding-right:56px;
    }
  }

  .btn-desiabled {
    cursor: not-allowed;
    background: #ccc;
    border: none;
  }
}
</style>
